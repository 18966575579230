import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as TWEEN  from './js/tween.module.min.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

// scope in the file
const pointer = new THREE.Vector2();
const rayCaster = new THREE.Raycaster();
const rayon = 30;
// STL files
const Polyz = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Polyz.stl';
const Lesnegliges = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Lesnegliges.stl';
const Les7merveilles = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Les7merveilles.stl';
const Poupeedebronze = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Poupeedebronze.stl';
const Design5060 = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Design5060.stl';
const equilibre = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/equilibre.stl';
const Creativ = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Creativ.stl';
const Arbore = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Arbore.stl';
const Bamboo = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/Bamboo.stl';
const Domino = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/domino.stl';
const guacha = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/guacha.stl';
const brindesens = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/brindesens.stl';
const petitefille = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/petitefille.stl';
const horsdedanger = 'https://pierrefeuillelaiton.ensad-nancy.eu/model3d/horsdedanger.stl';
// END STL files

let toggle= false;
//Defintion Cartel 

const cartel = {
  "Arbores" : {
    ref:'Arbores',
    titre:'Arbore - Évane Jobart, Adriana Queiros, Asbjorn Guiganti',
    contenu : '<p>Arbore est un jeu d’équilibre. On peut y jouer seul ou à plusieurs. Il développe la sensibilité, l’attention, et les capacités d’analyse.</p><p>L’objectif est de composer la ramure d’un arbre en ajoutant sur le tronc en culbuto, une par une, les pièces qui viendront former ses branches. Mais attention, chaque nouvelle pièce emboîtée est un défi aux lois de la gravité, et met en péril l’équilibre de l’arbre. Plus le jeu avancera, plus celui-ci prendra des formes complexes et uniques... jusqu’à sa chute.</p>'
  },
  "Polyz" : {
    ref:'Polyz', 
    titre:'Polyz - Callista Puissegur, Augustin Blanchet',
    contenu: '<p>Polyz est un jeu de construction. Il est basé sur le polyèdre d’Albrecht Dürer, nommé d’après sa gravure Melencolia I de 1514. Ce solide possède huit faces, six d’entre elles sont des pentagones irréguliers et deux des triangles équilatéraux. Aimantées, ces différentes faces aimantées permettent une multitude de combinaisons. Au fil de l’assemblage, le jeu de construction se transforme peu à peu en un jeu d’équilibre et d’habileté.</p>'
   },
   "horsdedanger":
   {
    ref:'horsdedanger',
    titre: 'Hors de danger - Livia Demey, Alice Collin',
    contenu :'<p>Hors de danger a pour but de sensibiliser les joueurs à l’impact écologique de l’homme sur les espèces en voie de disparition.</p><p>Dans ce jeu de plateau, les joueurs choisissent un pion qui représente un animal menacé qu’il doit sauver. Tour à tour, chacun des participants tire une carte qui lui signifie d’avancer ou de reculer. Certaines d’entre elles correspondent à des actions qui ne sont pas favorables au développement des espèces, d’autres au contraire, mettent en lumière ce que nous pouvons faire pour les protéger. Le premier arrivé gagne la partie.</p>'
   },
   "Les7merveilles" :{
    ref: "Les7merveilles",
    titre: "Les 7 merveilles - Robin Klein, Mathis Copie",
    contenu: "<p>Les 7 merveilles est un tangram. Il représente les œuvres architecturales et artistiques extraordinaires qui sont entrées dans l'Histoire. Malheureusement, il ne reste aujourd'hui plus qu'un seul de ces trésors du monde antique.</p><p>Ce jeu permet de redécouvrir les sept merveilles du monde et de les replacer sur le globe en assemblant toutes les pièces.</p>" 
  },
  "brindesens" : {
    ref:"brindesens",
    titre:"Un brin de sens - Emma Deloy, Célio Mantsouaka",
    contenu:"<p>Ce jeu va mettre à l’épreuve votre mémoire ainsi que votre sens de la stratégie.</p><p>Un brin de sens se compose de 21 bâtonnets qui chacun correspond à un son. Le but du jeu est de laisser le dernier bâtonnet à l’adversaire. À tour de rôle, chacun des joueurs va devoir retirer un, deux ou trois bâtonnets de leur choix avec lesquels ils créeront une mélodie. À vous de jouer, frottez, tapez, entrechoquez en rythme !</p>"
  },
  "Creativ" : {
    ref:"Creativ",
    titre:"Creativ - Tom-Félix Serafino, Yeeun Lee",
    contenu:"<p>Creativ est un jeu de construction pour les enfants. Il leur permet de laisser parler leur imagination.</p><p>Le jeu est constitué de deux types de pièces : des tiges de différentes formes et des sphères qui les relient entre elles. Celles-ci permettent de multiples possibilités d’assemblages.</p>"
  },
  "Lesnegliges" : {
    ref:"Lesnegliges",
    titre:"Négligés - Océane Berger, Gustave Touzé",
    contenu:"<p>Les Négligés sont de petits supports en laiton conçus pour accueillir vos souvenirs de promenade, fleurs, feuilles, branches...</p><p>Avec les mini socles, valorisez des fragments de nature et composez un paysage qui vous ressemble. Il évoluera avec les saisons. Jouez avec les couleurs et les formes pour rendre hommage à la beauté du végétal.</p>"
  },
  "petitefille" : {
    ref:"petitefille",
    titre:"Théâtre rouge - Thomas Bougrain, Justin Arnould",
    contenu: "<p>Théâtre rouge met en scène un classique de la littérature jeunesse. L’enfant recrée la scène contée à chaque page du livret et développe son langage par la lecture et les images.</p>"

  },
  "Poupeedebronze" : {
    ref:"Poupeedebronze",
    titre:"Poupées de laiton - Anastasia Sinistsyna, Audrey Helin",
    contenu:"<p>Inspiré du conte du petit chaperon rouge, Poupées de laiton permet de créer sa propre histoire. Les poupées sont composées de trois parties empilables et interchangeables : les jambes, le torse et la tête. Le but est de les combiner comme on l’entend et d’ajouter à son personnage un accessoire tel que le chapeau, le châle ou la cape. Les joueurs peuvent, par exemple donner au loup les jambes du chaperon rouge et l’habiller du châle de la grand-mère.</p>"
  },
  "Design5060" :{
    ref:"Design5060",
    titre:"50’s & 60’s - Laurie Pennesi, Emilie Giordano",
    contenu:"<p>A l’aide d’une série de questions et d’anecdotes, le jeu 50’s & 60’s consiste à instruire le joueur de manière ludique sur l’histoire du design.</p><p>Les pions font références à trois assises iconiques du design de l’époque. Le plateau en bois constitué de cases indépendantes permet aux joueurs de placer les cases comme ils le souhaitent au début du jeu. Les symboles sur celles-ci permettent d’effectuer une action comme sauter une case, piocher une carte, ou encore pouvoir repositionner une ou plusieurs cases du plateau.</p>"
  },
  "equilibre":{
    ref:"equilibre",
    titre:"Aplomb - Axel Henriet, Mathieu Dubo",
    contenu:"<p>Le jeu d’équilibre et de stratégie invite deux joueurs à faire preuve de dextérité. Il associe un culbuto à un disque sur lequel on dispose des poids.</p><p>Aplomb a la particularité de pouvoir se jouer de deux manières différentes. Soit les adversaires posent les poids à tour de rôle sur le disque, soit ceux-ci sont préalablement placés dessus avant que les joueurs les retirent un à un.</p>"
  },
  "Bamboo":{
    ref:"Bamboo",
    titre:"Sabatage - Coline Cacheux, Hélène Erhard",
    contenu:"<p>Sabatage est un jeu de stratégie, de compétition et de réflexion sur le thème des jardins zen. Il comprend un plateau, quatre pions, vingt murs de bambou et un dé.</p><p>Le but est de traverser le plateau avant vos adversaires. Le dé vous permet d’avancer ou de bloquer la progression des autres joueurs en plaçant des murs de bambous sur leur chemin.</p>"
  },
  "Domino":{
    ref:"Domino",
    titre:"The oldies - Justin Cuny, Kenza Cebe, Félicia Stoehr",
    contenu:"<p>Le jeu se transmet au fil des générations, mais que reste-t-il une fois leurs pièces usées, cassées, perdues ? Comment redonner vie à nos souvenirs ?</p>"
  },
  "guacha":{
    ref:"guacha",
    titre:"Ne joue pas avec la nourriture - Lidia Paryz, Laure Eby, Marion Le Calvez",
    contenu:"<p>On nous interdit toujours de jouer avec la nourriture ... Et si cette fois, jouer dans son assiette était permis ? Bravez l’interdit !</p><p>Ne joue pas avec la nourriture a pour but de transformer la cuisine en un moment ludique, de partage. Nous avons créé huit outils différents à emboiter sur un manche pour mitonner un bon petit plat. Vous allez broyer, touiller, surfer sur la purée, rouler dans la farine ou naviguer dans la soupe... Les outils peuvent également être utilisés pour peindre ou sculpter. Laissez libre cours à votre imagination !</p><p>Nous avons choisi douze pièces de jeux de notre enfance pour créer des pions en laiton. The oldies fait écho aux moments de partage dans les cours de récréation où l’on s’échangeait des billes, des cartes ou des figurines. Les pions évoquent ces moments passés à travers divers mini-jeux que chacun peut s’approprier.</p>"
  }




   
  };







//Material 
let copperMaterial = new THREE.MeshPhysicalMaterial({
  color: "#ffda8a", // set to white to allow texture to color the material
  metalness : .8,
  roughness :.7,
  clearcoat : .4,
  clearcoatRoughness : .39,
  //reflectivity : 1,
  });

// END Material


function init(canvas) {
  const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
  const myCanvas = document.querySelector('#myCanvas');
  const rect = myCanvas.getBoundingClientRect();
  renderer.setSize(rect.width, rect.height);
  renderer.setPixelRatio(window.devicePixelRatio);
  const scene = new THREE.Scene();
  const myGroup = new THREE.Group();
  // Créer la caméra
  const aspectRatio = rect.width / rect.height;
  const cameraDistance = 20; // Distance de la caméra par rapport à la scène
  const cameraHeight = 5; // Hauteur de la caméra

  const camera = new THREE.PerspectiveCamera(50, aspectRatio, 0.1, 1000);
  camera.position.set(10, cameraHeight, cameraDistance);
  camera.lookAt(0, 0, 0); // Faire en sorte que la caméra regarde le centre
  // Ajouter un fond gris clair
  scene.background = new THREE.Color(0xeeeeee);
  scene.add(camera);
  // Ajouter des lumières
  
  //scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0, rayon, 4);
  scene.add(directionalLight);
  const targetObject = new THREE.Object3D(); 
  targetObject.position.set(0, rayon, 0);
  scene.add(targetObject);
  directionalLight.target = targetObject;
  scene.add(directionalLight.target);  
  const groundGeometry = new THREE.PlaneGeometry(700, 700);
  const groundMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });
  const ground = new THREE.Mesh(groundGeometry, groundMaterial);
  ground.name ="sol";
  ground.position.set(0, 0, 0);
  ground.rotation.x = -Math.PI / 2;
  //scene.add(ground);

//Grid Helper
const grid = new THREE.GridHelper(100, 10);
//scene.add(grid);

  //default material for stl object
  const physical_material = new THREE.MeshPhysicalMaterial({ color: 'gold' });
  physical_material.reflectivity = 1;
  physical_material.metalness = 1;
  
//Create all objects from ./assets/models_stl
const nbreObjets = (14 -1)/2; //nb/2
  const loader = new STLLoader();
  //0
  loader.load(
  Polyz,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry, copperMaterial);
      mesh.position.set(rayon, 0, 0);
      mesh.scale.set(.3,.3,.3);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
     // mesh.rotation.x= -Math.PI/2;
      mesh.name = "Polyz";
      mesh.geometry.center();
      myGroup.add(box);
      myGroup.add(mesh);
       
    } catch (error) { }
  }
  );
  //1
  loader.load(
  Lesnegliges,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(1*Math.PI/nbreObjets), rayon*Math.sin(1*Math.PI/nbreObjets), 0);
      mesh.scale.set(.08,.08,.08);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    //  mesh.rotation.x= -Math.PI/2;
      mesh.name = "Lesnegliges";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {}
    },(xhr) => {
  },
  (error) => {}
  );
  //2
  loader.load(
      horsdedanger,
      function (geometry) {
        try {
          const mesh = new THREE.Mesh(geometry,  copperMaterial);
          mesh.position.set(rayon*Math.cos(2*Math.PI/nbreObjets), rayon*Math.sin(2*Math.PI/nbreObjets), 0);
          mesh.scale.set(.04,.04,.04);
         /// mesh.rotation.x= -Math.PI/2;
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.name = "horsdedanger";
          mesh.geometry.center();
          myGroup.add(mesh);
           
        } catch (error) {
          console.error("An error occurred while loading the STL file:", error);
        }
      }
   );


  //3
  loader.load(
    Les7merveilles,
    function (geometry) {
      try {
        const mesh = new THREE.Mesh(geometry,  copperMaterial);
        mesh.position.set(rayon*Math.cos(3*Math.PI/nbreObjets), rayon*Math.sin(3*Math.PI/nbreObjets), 0);
        mesh.scale.set(.3, .3, .3);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      //  mesh.rotation.x= Math.PI/6;
        mesh.name = "Les7merveilles";
        mesh.geometry.center();
        myGroup.add(mesh);
         
      } catch (error) {
        console.error("An error occurred while loading the STL file:", error);
      }
    },
    (xhr) => {
      console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
    },
    (error) => {
      console.log("Failed to load the STL file:", error);
    }
    );

 //4
  loader.load(
  Poupeedebronze,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(4*Math.PI/nbreObjets), rayon*Math.sin(4*Math.PI/nbreObjets), 0);
      mesh.scale.set(.2,.2,.2);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
     // mesh.rotation.x= -Math.PI/2;
      mesh.name = "Poupeedebronze";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //5
  loader.load(
  Design5060,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(5*Math.PI/nbreObjets),  rayon*Math.sin(5*Math.PI/nbreObjets), 0 );
      mesh.scale.set(.05,.05,.05);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    //  mesh.rotation.x= -Math.PI/2;
      mesh.name = "Design5060";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //6
  loader.load(
  equilibre,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(6*Math.PI/nbreObjets),  rayon*Math.sin(6*Math.PI/nbreObjets), 0.7);
      mesh.scale.set(.05,.05,.05);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "equilibre";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //7
  loader.load(
    brindesens,
      function (geometry) {
        try {
          const mesh = new THREE.Mesh(geometry,  copperMaterial);
          mesh.position.set(rayon*Math.cos(7*Math.PI/nbreObjets), rayon*Math.sin(7*Math.PI/nbreObjets), 0);
          mesh.scale.set(.02, .02, .02);
          mesh.rotation.x= -Math.PI/2
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.name = "brindesens";
          mesh.geometry.center();
          myGroup.add(mesh);
           
        } catch (error) {
          console.error("An error occurred while loading the STL file:", error);
        }
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.log("Failed to load the STL file:", error);
      }
      );
  //8
  loader.load(
  Arbore,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(8*Math.PI/nbreObjets), rayon*Math.sin(8*Math.PI/nbreObjets), 0);
      mesh.scale.set(.06,.06,.06);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "Arbores";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //9 Bamboo
  loader.load(
  Bamboo,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(9*Math.PI/nbreObjets), rayon*Math.sin(9*Math.PI/nbreObjets), 0);
      mesh.scale.set(.2,.2,.2);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "Bamboo";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //10 Domino
  loader.load(
  Domino,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(10*Math.PI/nbreObjets),rayon*Math.sin(10*Math.PI/nbreObjets), 0);
      mesh.scale.set(.1,.1,.1);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "Domino";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //11 guacha
  loader.load(
  guacha,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(11*Math.PI/nbreObjets), rayon*Math.sin(11*Math.PI/nbreObjets), 0);
      mesh.scale.set(.1,.1,.1);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "guacha";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
  //12 petitefille
  loader.load(
  petitefille,
  function (geometry) {
    try {
      const mesh = new THREE.Mesh(geometry,  copperMaterial);
      mesh.position.set(rayon*Math.cos(12*Math.PI/nbreObjets), rayon*Math.sin(12*Math.PI/nbreObjets), 0);
      mesh.scale.set(.08,.08,.08);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.name = "petitefille";
      mesh.geometry.center();
      myGroup.add(mesh);
       
    } catch (error) {
      console.error("An error occurred while loading the STL file:", error);
    }
  },
  (xhr) => {
    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
  },
  (error) => {
    console.log("Failed to load the STL file:", error);
  }
  );
    //13 Creativ
    loader.load(
      Creativ,
      function (geometry) {
        try {
          const mesh = new THREE.Mesh(geometry,  copperMaterial);
          mesh.position.set(rayon*Math.cos(13*Math.PI/nbreObjets),  rayon*Math.sin(13*Math.PI/nbreObjets), 0);
          mesh.scale.set(.08,.08,.08);
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.name = "Creativ";
          mesh.geometry.center();
          myGroup.add(mesh);
           
        } catch (error) {
          console.error("An error occurred while loading the STL file:", error);
        }
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.log("Failed to load the STL file:", error);
      }
      );
  myGroup.rotation.x = - Math.PI/2;
  scene.add( myGroup );
  // Ajouter les contrôles 
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.rotateSpeed = 0.5;
  controls.enablePan = true; // Désactiver les déplacements verticaux
  controls.enableRotate = true; // Désactiver la rotation horizontale
  controls.minDistance = 1;
  controls.maxDistance = 300;
  // Mouse clicked 
  document.addEventListener(
  "click",
  (event) => {
    const rect = renderer.domElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    pointer.x = ( x / canvas.clientWidth ) *  2 - 1;
    pointer.y = ( y / canvas.clientHeight) * - 2 + 1;
    camera.updateMatrixWorld();
    rayCaster.setFromCamera( pointer, camera);
    const intersects = rayCaster.intersectObjects( scene.children );
    if ( intersects.length > 0 ) {
      const myObject = intersects[0].object;
      console.log("click", myObject.name);
      if( myObject.name.length>1 && !toggle ){
        modifyNewScene( myObject );
      }
    }
});
//Mouse Move
function onPointerMove( event ) {
  // calculate pointer position in normalized device coordinates
  // (-1 to +1) for both components
  const rect = renderer.domElement.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  
  pointer.x = ( x / canvas.clientWidth ) *  2 - 1;
  pointer.y = ( y / canvas.clientHeight) * - 2 + 1;

  rayCaster.setFromCamera( pointer, camera);
  const intersects = rayCaster.intersectObjects( scene.children );
  if ( intersects.length > 0 ) {
    console.log("hover", intersects[0].object.name);
    
  }
}
window.addEventListener( 'pointermove', onPointerMove);


//  new window for modal  //////
const modal = document.querySelector(".modal");
const modalContent = document.querySelector(".modal-content");
const modalCloseBtn = document.querySelector(".modalCloseBtn");
const modalLeft = document.querySelector('.modal-content-left');
const modalRight = document.querySelector('.modal-content-right');
const newGroup = new THREE.Group();
// on cree une nouvelle scene three.js pour utilisation ulterieure danns le modal-content
let newScene = new THREE.Scene();
newScene.background = new THREE.Color(0xeeeeee);

let newCamera = new THREE.PerspectiveCamera(50, modalLeft.clientWidth / modalLeft.clientHeight, .1, 10000);
const newRenderer = new THREE.WebGLRenderer({antialias: true,})
const newDirectionalLight = new THREE.DirectionalLight(0xffffff, 1);
newDirectionalLight.position.set(0, 4, 5);
newScene.add(newDirectionalLight);
newCamera.enablePan = true;
const modalControls = new OrbitControls(newCamera, newRenderer.domElement);
modalControls.autoRotate = true
modalControls.autoRotateSpeed = 5
modalControls.minZoom =1
modalControls.maxZoom = 10
modalControls.minDistance = .5
modalControls.maxDistance = 100

function newAnimate() {
  requestAnimationFrame(newAnimate);
}
function generateNewScene () {
  //petit spot monté sur la camera
  const spot = new THREE.DirectionalLight(0xffffff, .3);
  newCamera.add( spot );
  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  newCamera.position.set(10, cameraHeight, cameraDistance);
  newScene.add(newCamera);
  newCamera.lookAt(0, 0, 0);
  newScene.add(ambientLight);
  newGroup.rotation.x= -Math.PI/2;
  newScene.add(newGroup);
  newRenderer.setSize(modalLeft.clientWidth, modalLeft.clientHeight);
  newRenderer.render(newScene, newCamera);
  modalLeft.appendChild(newRenderer.domElement);
  modalControls.update();
}
generateNewScene()
newAnimate()


function modifyNewScene( element3d ){
  toggle = true;
  newCamera.position.set(10, cameraHeight, cameraDistance);
  const new3d = element3d.clone();
  new3d.position.set(0,0,0);
  new3d.castShadow = true;
  new3d.receiveShadow = true;
  newGroup.add( new3d );
  modal.style.left = 0;

  //add content in right 
  if( cartel[element3d.name]){
    const title = cartel[element3d.name].titre;
    const content = cartel[element3d.name].contenu;
    modalRight.innerHTML = `<h2>${title}</h2>
    ${content}`
    
  } else {
    modalRight.innerHTML ="";
  }

}


// fonction appeller pour le rendu de la scene
function render() {
  // Rendu de la scène
  directionalLight.updateMatrixWorld();
  renderer.render(scene, camera); 
  newRenderer.render(newScene, newCamera);


} 
// Fonction d'animation
function animate() {
  requestAnimationFrame(animate);
  newAnimate();
  modalControls.update();
  //controls.update();
  myGroup.rotation.z += .001;
  render();
  
}
// Appel de la fonction d'animation
animate();


modalCloseBtn.addEventListener('click', ()=>{
  toggle = false;
  newGroup.remove(...newGroup.children);
  modal.style.left = "-5000px";
})
    


}// fin function init()

document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    const myCanvas = document.querySelector('#myCanvas');
    init( myCanvas );
   }
}

